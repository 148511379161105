import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import Onboarding from "../../components/prices/Onboarding"
import TermsSwiss from "../../components/prices/TermsSwiss"
import TermsGermany from "../../components/prices/TermsGermany"
import Accordion from "../../components/AccordionBlock"

import { Helmet } from "react-helmet"

import { deDE } from "../../lang/de-DE"

export default function Features() {
  const location = useLocation()

  const country = "DE"
  const lang = "de-DE"
  let txt = deDE.prices

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="DoryGo - Unsere Preise"
        description="So viel kostet unser rundum sorglos Paket"
        lang={lang}
      />
      <Helmet>
        <link
          rel="alternate"
          hreflang="de-DE"
          href="https://dorygo.com/de-de/preise"
        />
        <link
          rel="canonical"
          hreflang="de-CH"
          href="https://dorygo.ch/ch-de/preise"
        />
      </Helmet>

      <TermsGermany txt={txt}/>
      {/*}
      <Onboarding txt={txt.Onboarding} />

      */}
      <Accordion input ={txt.FAQ}/>  
    </Layout>
  )
}
