import React from "react"
import * as S from "./styledComponents"
import * as GS from "../../SharedStyledComponents"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function Component({ txt }) {
  return (
    <S.OuterDiv>
      <S.Banner>
        <S.LargeTxt>{txt.Benefit1}</S.LargeTxt>
        <S.SubText>{txt.Benefit2}</S.SubText>
      </S.Banner>

      <S.Pricing>
        {/*<S.AlertTxt>{txt.Alert1}</S.AlertTxt>*/} {/* use if setting up special prices etc */}
        <S.SmallTxt>{txt.SmallTxt1}</S.SmallTxt>

        <S.Price dangerouslySetInnerHTML={{ __html: txt.Price }} />
        <Link to="/checkout/?country=DE">
          <GS.Cta>{txt.BtnTxt}</GS.Cta>
        </Link>
        <S.PromiseBanner>
          <S.PromiseDiv>
            <S.Icon>
              <StaticImage
                src="../../../icon/haken_vorteile_256x256.png"
                alt="Icon"
              />
            </S.Icon>
            <S.Promise>{txt.Promise1}</S.Promise>
          </S.PromiseDiv>
          <S.PromiseDiv>
            <S.Icon>
              <StaticImage
                src="../../../icon/haken_vorteile_256x256.png"
                alt="Icon"
              />
            </S.Icon>
            <S.Promise>{txt.Promise2}</S.Promise>
          </S.PromiseDiv>
        </S.PromiseBanner>
      </S.Pricing>
    </S.OuterDiv>
  )
}
