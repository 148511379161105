import styled from "styled-components"



export const OuterDiv = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  
`

export const Banner = styled.div`
padding: 2rem 1rem;
background-color: var(--secondary20);
width: 100%;
display:flex;
flex-direction:column;
align-items:center;
`

export const LargeTxt= styled.p`
font-size: 2rem;
font-weight:bold;
text-align:center;

`

export const SubText = styled.p``

export const  Pricing = styled.div`
padding: 2rem 1rem;
display:flex;
flex-direction: column;
align-items: center;
margin-top: 1rem;

@media (min-width: 768px) {

  margin-top: 3rem;
  margin-bottom: 2rem;
  } 



@media (min-width: 1280px) {
  min-height: 50vh;
  margin-top: 4rem;
  } 


`

export const SmallTxt = styled.p`
font-weight: bold;
margin:0;

`

export const AlertTxt = styled.p`
color:red;
font-weight: bold;
margin:1rem 0 2rem;
font-size:2rem;

text-align:center;



`

export const Price = styled.p`
font-size: 2rem;
font-weight:bold;
margin: 1rem 0 3rem;


`


export const PromiseBanner = styled.div`
display: flex;
flex-direction: column;


@media (min-width: 768px) {
  flex-direction: row;
  margin-top: 3rem;
}


`


export const PromiseDiv = styled.div`
display: flex;
flex-direction: row;
align-items: center;

`



export const Icon = styled.div`

  width: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0.5rem 1rem;`

export const Promise = styled.p`

margin:0;

`