import styled from "styled-components"

export const OuterDiv = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StepWrapper = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 960px) {
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    max-width: 80vw;
  }
`
export const StepDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;

  @media (min-width: 960px) {
    margin: 1rem 1rem;
    width: 25%;
    min-height: 10rem;
  }
`

export const StepNumber = styled.p`
  font-size: 5rem;
  font-weight: bold;
  color: var(--secondary100);
  margin: 0 1rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 960px) {
    align-items: flex-start;
  }
`

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1 rem;
`

export const StepTitle = styled.h3`
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.5rem;
  font-weight: bold;

  @media (min-width: 960px) {
    min-height: 4rem;
  }
`

export const StepBody = styled.p`
  font-size: 1rem;
  margin: 0;
`

export const Title = styled.h2`
  font-size: 2rem;
  padding: 0 1rem;
  text-align: center;
  margin: 0;
`
